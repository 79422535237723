import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isSubmitting, submit } from 'redux-form';
import { getProp } from '@sportnet/utilities';
import Api from '../../../../Api';
import ReadOnlyTextArea from '../../../../components/ReadOnlyTextArea';
import { getRequestStatusName, __ } from '../../../../utilities';
import { appspaceDefinition } from '../../../App/definitions';
import { appspaceSelector } from '../../../App/selectors';
import { receiveRequestDetailSuccess } from '../actions';
import { sportExpertOrgRequestDefinition } from '../../definitions';
import StatusManager from '../Universal/StatusManager';
import Form, { formName } from './form';
import Icon from '@sportnet/ui/Icon';

class SportExpertOrgManager extends PureComponent {
  state = {
    loading: false,
    concernedOrganizations: [],
    isFetchingConcernedOrganizations: false,
  };

  componentDidMount() {
    this.getConcernedOrganizations(this.props.request);
  }

  componentDidUpdate(prev) {
    if (
      prev.request &&
      this.props.request &&
      prev.request.status !== this.props.request.status
    ) {
      this.getConcernedOrganizations(this.props.request);
    }
  }

  getConcernedOrganizations = request => {
    this.setState(
      {
        concernedOrganizations: [],
        isFetchingConcernedOrganizations: true,
      },
      async () => {
        try {
          const promises = [];
          request.confirmedBy.forEach(confirmation => {
            promises.push(Api.organizationPPOProfile(confirmation.appSpace));
          });
          const organizations = await Promise.all(promises);
          this.setState({
            concernedOrganizations: organizations,
          });
        } catch (e) {
          //
        } finally {
          this.setState({
            isFetchingConcernedOrganizations: false,
          });
        }
      },
    );
  };

  changeStatus = async status => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      request,
    } = this.props;
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')}
${__('z')} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;

    try {
      this.setState({
        loading: true,
      });
      let newRequest = {};
      if (status === 'REJECTED') {
        const noteText = __('Napíšte dôvod zamietnutia:');
        // eslint-disable-next-line
        const note = window.prompt(`${confirmMessage}
        
${noteText}`);
        if (!note) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status, note },
        });
      } else {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status },
        });
      }
      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      // failed
      const error = getProp(e, ['details'], {});
      if (error.name === 'INVALID_IDNR') {
        // eslint-disable-next-line
        window.alert(__('Neplatné Identifikačné číslo používateľa'));
      } else if (error.statusCode === 409 && error.name === 'DUPLICATED_IDNR') {
        // eslint-disable-next-line
        window.alert(
          __(
            'Rodné číslo už v systéme existuje. Nie je možné schváliť žiadosť.',
          ),
        );
      } else if (
        error.statusCode === 409 &&
        error.name === 'DUPLICATED_REGNR'
      ) {
        // eslint-disable-next-line
        window.alert(
          __(
            'Registračné číslo už v systéme existuje. Skontrolujte prosím nastavenie sekvencera pre generovanie registračných čísiel.',
          ),
        );
      } else if (
        error.statusCode === 403 &&
        error.name === 'GUARDIAN_FORBIDDEN'
      ) {
        // eslint-disable-next-line
        window.alert(
          __(
            'Zadávateľ žiadosti nie je oprávnená osoba na registráciu zadaného rodného čísla.',
          ),
        );
      } else {
        console.error(error);
        // eslint-disable-next-line
        window.alert(
          `${__('Došlo k neznámej chybe')} (${error.statusCode}:${error.name}:${
            error.description
          })`,
        );
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleCancel = () => {
    this.changeStatus('CANCELLED');
  };

  handleNew = () => {
    this.changeStatus('NEW');
  };

  handleReject = () => {
    this.changeStatus('REJECTED');
  };

  handleConfirm = () => {
    this.props.dispatch(submit(formName));
  };

  handleSubmit = async values => {
    const status = 'CONFIRMED';
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      request,
    } = this.props;
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')}
${__('z')} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;
    // eslint-disable-next-line
    if (!window.confirm(confirmMessage)) return;
    try {
      const newRequest = await Api.crmConfirmSportExpertOrgRequest(
        appspace,
        request._id,
        {
          data: values,
        },
      );
      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      // failed
      const error = getProp(e, ['details'], {});
      if (error.name === 'INVALID_IDNR') {
        // eslint-disable-next-line
        window.alert(__('Neplatné Identifikačné číslo používateľa'));
      } else if (error.statusCode === 409 && error.name === 'DUPLICATED_IDNR') {
        // eslint-disable-next-line
        window.alert(__('Osoba so zadaným rodným číslom už existuje.'));
      } else if (
        error.statusCode === 403 &&
        error.name === 'GUARDIAN_FORBIDDEN'
      ) {
        // eslint-disable-next-line
        window.alert(
          __(
            'Zadávateľ žiadosti nie je oprávnená osoba na registráciu zadaného rodného čísla.',
          ),
        );
      } else {
        console.error(error);
        // eslint-disable-next-line
        window.alert(
          `${__('Došlo k neznámej chybe')} (${error.statusCode}:${error.name}:${
            error.description
          })`,
        );
      }
    }
  };

  handleClose = () => {
    this.changeStatus('CLOSED');
  };

  render() {
    const { request, isFormSubmitting, appspace } = this.props;
    const userLicense = getProp(request, ['data', 'user_license'], null);
    const initialFormValues = {
      verified_by_person: appspace.display_name || '',
      verified_by_position: appspace.role || '',
    };
    /*
    const { _id, ...modifiedSportnetUser } = getProp(
      this.props.request,
      ['data', 'sportnet_user'],
      {},
    );
    const { sportnet_id, ...sportnetUser } = getProp(
      this.props.request,
      ['user'],
      {},
    );
    */
    const isChildRegistration = getProp(
      this.props.request,
      ['data', 'isChildRegistration'],
      false,
    );
    return (
      <Fragment>
        <StatusManager
          request={request}
          loading={this.state.loading || isFormSubmitting}
          onCancel={this.handleCancel}
          onNew={this.handleNew}
          onReject={this.handleReject}
          onConfirm={this.handleConfirm}
          onClosed={this.handleClose}
        />
        <FormGroup>
          <Label>{__('Registrácia dieťaťa: ')}</Label>
          <ReadOnlyTextArea>
            {isChildRegistration ? __('Áno') : __('Nie')}
          </ReadOnlyTextArea>
        </FormGroup>
        {/*
        {modifiedSportnetUser && !isChildRegistration && (
          <UserProfileDeepCompare
            sportnetUser={sportnetUser}
            modifiedSportnetUser={modifiedSportnetUser}
          />
        )}
          */}
        {this.state.concernedOrganizations.length > 0 && (
          <FormGroup>
            <Label>{__('Potvrdzujúce organizácie: ')}</Label>
            {this.state.concernedOrganizations.map(org => (
              <ReadOnlyTextArea key={org._id}>
                {org.name}{' '}
                <Icon
                  size="s"
                  name={
                    (
                      this.props.request.confirmedBy.find(
                        ({ appSpace }) => appSpace === org._id,
                      ) || {}
                    ).confirmed
                      ? 'check'
                      : 'visibility'
                  }
                />
              </ReadOnlyTextArea>
            ))}
          </FormGroup>
        )}
        {(request.status === 'APPROVED' ||
          request.status === 'PARTIALLY_CONFIRMED') && (
          <Form
            onSubmit={this.handleSubmit}
            initialValues={initialFormValues}
            showFields={!!userLicense}
          />
        )}
      </Fragment>
    );
  }
}

SportExpertOrgManager.propTypes = {
  request: sportExpertOrgRequestDefinition.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
    }).isRequired,
  }).isRequired,
  appspace: appspaceDefinition.isRequired,
  isFormSubmitting: PropTypes.bool,
};

SportExpertOrgManager.defaultProps = {
  isFormSubmitting: false,
};

const mapStateToProps = state => {
  return {
    appspace: appspaceSelector(state),
    isFormSubmitting: isSubmitting(formName)(state),
  };
};

const routerConnected = withRouter(SportExpertOrgManager);
export default connect(mapStateToProps)(routerConnected);
