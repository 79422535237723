import FormGroup from '@sportnet/ui/FormGroup';
import Label from '@sportnet/ui/Label/Label';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import getProp from '@sportnet/utilities/getProp';
import Api from '../../../../Api';
import ReadOnlyTextArea from '../../../../components/ReadOnlyTextArea';
import { getRequestStatusName, __ } from '../../../../utilities';
import { receiveRequestDetailSuccess } from '../actions';
import { sportOrgRequestDefinition } from '../../definitions';
import StatusManager from '../Universal/StatusManager';
import Icon from '@sportnet/ui/Icon';

class TransferManager extends PureComponent {
  state = {
    loading: false,
  };

  changeStatus = async status => {
    const {
      dispatch,
      match: {
        params: { appspace },
      },
      request,
    } = this.props;
    const confirmMessage = `${__('Naozaj chcete zmeniť stav žiadosti')}
${__('z')} ${getRequestStatusName(request.status)} ${__(
      'na',
    )} ${getRequestStatusName(status)}?`;

    try {
      this.setState({
        loading: true,
      });
      let newRequest = {};
      if (status === 'REJECTED') {
        const noteText = __('Napíšte dôvod zamietnutia:');
        // eslint-disable-next-line
        const note = window.prompt(`${confirmMessage}
        
${noteText}`);
        if (!note) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status, note },
        });
      } else if (status === 'CONFIRMED') {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmConfirmTransferRequest(
          appspace,
          request._id,
          {
            data: {},
          },
        );
      } else {
        // eslint-disable-next-line
        if (!window.confirm(confirmMessage)) return;
        newRequest = await Api.crmChangeRequestStatus(appspace, request._id, {
          data: { status },
        });
      }
      dispatch(receiveRequestDetailSuccess(newRequest._id, newRequest));
    } catch (e) {
      // failed
      const error = getProp(e, ['details'], {});
      if (error.name === 'XX_ERROR') {
        // eslint-disable-next-line
        window.alert(__('XX_ERROR'));
      } else {
        console.error(error);
        // eslint-disable-next-line
        window.alert(
          `${__('Došlo k neznámej chybe')} (${error.statusCode}:${error.name}:${
            error.description
          })`,
        );
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  handleCancel = () => {
    this.changeStatus('CANCELLED');
  };

  handleNew = () => {
    this.changeStatus('NEW');
  };

  handleReject = () => {
    this.changeStatus('REJECTED');
  };

  handleConfirm = () => {
    this.changeStatus('CONFIRMED');
  };

  handleClose = () => {
    this.changeStatus('CLOSED');
  };

  render() {
    return (
      <React.Fragment>
        <StatusManager
          request={this.props.request}
          loading={this.state.loading}
          onCancel={this.handleCancel}
          onNew={this.handleNew}
          onReject={this.handleReject}
          onConfirm={this.handleConfirm}
          onClosed={this.handleClose}
        />
        {this.props.request.confirmedBy &&
          this.props.request.confirmedBy.length > 0 && (
            <FormGroup>
              <Label>{__('Potvrdzujúce organizácie: ')}</Label>
              {this.props.request.confirmedBy.map(c => (
                <ReadOnlyTextArea key={c.appSpace}>
                  {c.ppoName}{' '}
                  <Icon size="s" name={c.confirmed ? 'check' : 'visibility'} />
                </ReadOnlyTextArea>
              ))}
            </FormGroup>
          )}
      </React.Fragment>
    );
  }
}

TransferManager.propTypes = {
  request: sportOrgRequestDefinition.isRequired,
  dispatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      appspace: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

const mapStateToProps = () => {
  return {};
};

const routerConnected = withRouter(TransferManager);
export default connect(mapStateToProps)(routerConnected);
